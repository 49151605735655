import {jwtDecode} from 'jwt-decode';
import { useEffect, useState } from 'react';

const ProtectedRoute = ({ children }) => {
    const [isTokenProcessing, setIsTokenProcessing] = useState(true); // Tambahkan state untuk memproses token
    const redirectUrl = encodeURIComponent(window.location.href);
    const sso_url = `https://sso.hasilkarya.com?redirect=${redirectUrl}`;

    const processTokenFromUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenFromUrl = urlParams.get('token');

        if (tokenFromUrl) {
            // Simpan token di localStorage
            localStorage.setItem('token', tokenFromUrl);
            // Hapus token dari URL agar tidak muncul di address bar
            window.history.replaceState(null, null, window.location.pathname);
        }

        setIsTokenProcessing(false); // Selesai memproses token
    };

    // Gunakan useEffect untuk memproses token dari URL setelah komponen dimuat
    useEffect(() => {
        processTokenFromUrl();
    }, []);

    // Ambil kembali token dari localStorage setelah memproses URL
    const token = localStorage.getItem('token');

    if (isTokenProcessing) {
        // Jika masih memproses token, jangan lakukan apapun
        return null;
    }

    if (!token) {
        // Jika tidak ada token, arahkan ke halaman login SSO
        window.location.href = sso_url;
        return null;
    }

    // Validasi token
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
            // Jika token sudah kedaluwarsa, hapus token dan arahkan ke halaman login SSO
            localStorage.removeItem('token');
            window.location.href = sso_url;
            return null;
        }
    } catch (error) {
        // Jika ada kesalahan saat mendekode token, anggap token tidak valid
        localStorage.removeItem('token');
        window.location.href = sso_url;
        return null;
    }

    // Jika token valid, render anaknya (children)
    return children;
};

export default ProtectedRoute;
