import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ProtectedRoute from './routes/ProtectedRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route 
            path="/" 
            element={
                <ProtectedRoute>
                    <Home />
                </ProtectedRoute>
            } 
        />
      </Routes>
    </Router>
  );
}

export default App;