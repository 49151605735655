import React from 'react';

const Home = () => {
  const styles = {
    container: {
      backgroundColor: '#fffff0', // Background warna #fffff0
      color: '#204080', // Warna teks utama #204080
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center',
      flexDirection: 'column',
    },
    image: {
      width: '150px', // Atur ukuran gambar
      height: 'auto',
      marginBottom: '1rem',
    },
    title: {
      fontSize: '3rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
    subtitle: {
      fontSize: '1.5rem',
      color: '#ce2226', // Warna teks kedua #ce2226
    },
    button: {
      backgroundColor: '#204080', // Warna latar belakang tombol
      color: '#fffff0', // Warna teks tombol
      padding: '0.75rem 1.5rem',
      fontSize: '1rem',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      marginTop: '1.5rem',
    },
  };

  return (
    <div style={styles.container}>
      <img 
        src={require('../assets/Image/Logo Perusahaan Typing.png')} 
        alt="Company Logo" 
        style={styles.image}
      />
      <div style={styles.title}>Welcome to the Second Project</div>
      <div style={styles.subtitle}>We're glad to have you here!</div>
      <button style={styles.button}>Get Started</button>
    </div>
  );
};

export default Home;
